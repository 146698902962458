import * as React from "react"
import GoogleReviews from "../../images/google-reviews-white.svg"
import  './GoogleRating.scss';

const GoogleRating = ( props ) => {

  return(
<div className="google-rating">
  <div>
  <picture>
         <source media="(min-width:768px)" srcSet={GoogleReviews}/>
          <img src={GoogleReviews} alt="Google Reviews - tlc Estate Agents" loading="lazy" width={120} height={48}/>
  </picture>
   
  </div>
  <p className="d-md-none"><strong>4.9/5</strong> from our valued customers.</p>
  <p className="d-none d-md-block">Rated <strong> 4.9 out of 5</strong> from our valued customers.</p>
</div>
)
  }



export default GoogleRating