import React, { useRef,useState,useEffect } from 'react';
import { Link } from "gatsby";
import { Col, Container, Row } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import { isIOS } from 'react-device-detect';
import { GetURL } from '../utils';
import './BookAppointment.scss';
import ReapitBook from '../ReapitBook';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const BookAppointment = (props) => {
    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
        if (isIOS) {
          setRenderComponent(true)
        }else {
        window.addEventListener("mousemove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })}
    }, [])
    
    return (
         <div className='appointment-section' style={{ background: `url(${renderComponent ? getImage(props?.data?.Background_Image?.url_sharp)?.images?.fallback?.src : ""})`}}> 
            <Container>
                <Row>
                    <Col>
                        <ScrollAnimation animateIn="fadeInUp" >
                            <div className="appointment-content">
                                <span className='sub-title'>{props?.data?.Sm_Title}</span>
                                <h2>{props?.data?.Title}</h2>
                                <div className="btn-row">
                                    {renderComponent && ( <>
                                        <ReapitBook className="btn" type="[&quot;sales&quot;,&quot;lettings&quot;]">{props?.data?.Cta_1_Label}</ReapitBook>
                                        <Link to={GetURL(props?.data?.Cta_2_Link._id)} className="btn">{props?.data?.Cta_2_Label}</Link>
                                        </>
                                    )}
                                </div>
                            </div>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

export default BookAppointment;
