import React, { useRef,useState,useEffect } from 'react';
// import React from "react";
import handleViewport from "react-in-viewport";
import { isIOS } from 'react-device-detect';
import Header from "./Header/Header";
import HomepageBanner from "./Banner/Banner";
import Footer from "./Footer/Footer";
import BookAppointment from "./BookAppointment/BookAppointment";
import loadable from '@loadable/component'

// import AboutUs from "./AboutUs/AboutUs";
const AboutUs =loadable(() => import ('./AboutUs/AboutUs'))

//const BookAppointment =loadable(() => import ('./BookAppointment/BookAppointment'))

// import Counter from "./Counter/Counter";
const Counter =loadable(() => import ('./Counter/Counter'))

// import CustomerReview from "./CustomerReview/CustomerReview";
const CustomerReview =loadable(() => import ('./CustomerReview/CustomerReview'))

// import LocalLiving from "./LocalLiving/LocalLiving";
const LocalLiving =loadable(() => import ('./LocalLiving/LocalLiving'))

// import Newsevents from "./NewsEvents/NewsEvents";
const Newsevents =loadable(() => import ('./NewsEvents/NewsEvents'))

// import PropertySpecilist from "./PropertySpecilist/PropertySpecilist";
const PropertySpecilist =loadable(() => import ('./PropertySpecilist/PropertySpecilist'))

const CounterBlk = props => {
    const { inViewport, forwardedRef, enterCount } = props;
    return (
        <div ref={forwardedRef}>
            {(inViewport || enterCount > 0) && <Counter {...props}/>}
        </div>
    )
}

const CounterBlock = handleViewport(CounterBlk, {
    config: {
        disconnectOnLeave: true
    }
});
console.log('yes loaded')

const HomeModules = (props) => { 

    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
        if (isIOS) {
          setRenderComponent(true)
        }else {
        window.addEventListener("mousemove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })}
    }, [])

    return (
        <div className={props.page.CSS_Class ? props.page.CSS_Class : props.page.Alias}>
            <Header
                menuLinks={props.page.Layout === "Banner_with_Links" ? props?.modules?.filter(c => c.List_of_Links !== undefined)[0]?.List_of_Links : []}
            />
            {props.page.Layout === "Banner_with_Links" && 
                <HomepageBanner
                    data={props.page}
                    modules={props.modules}
                />
            }
            {props.modules.map((module, i) => 
                <React.Fragment key={i}>
                    {module.Data_Type === "List_Stats" && 
                        <CounterBlock
                            data={module}
                        />
                    }                  
                       {module.Type === "Horizontal_Lists" && 
                        <PropertySpecilist
                            data={module}
                        />
                    }
                    {module.Type === "Split_View_Video_Ask" && 
                        <AboutUs
                            data={module}
                        />
                    }
                    {module.Data_Type === "Background_Image" && 
                        <LocalLiving
                            data={module}
                        />
                    }
                    {module.Data_Type === "Google_Reviews" && 
                        <CustomerReview
                            data={module}
                        />
                    }
                     {module.Choose_Collecttion === "News_Collection_Homepage" && renderComponent &&
                        <Newsevents
                            path="/about/latest-property-news-and-videos/"
                            data={module}
                        />
                    }
                </React.Fragment>
            )}
            {props.page.Show_Valuation_Block &&
                <BookAppointment
                    data={props.globalModule?.Lets_Work_Together}
                />
            }
            <Footer
                popularSearch={props.page?.Popular_Search}
            />
            
        </div>
    );
};

export default HomeModules;