import React from "react";
import { Helmet } from "react-helmet";
import "../scss/global.scss";
import "../scss/grunticon.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../scss/bootstrap-overrides.scss"
import "../scss/custom.scss";   
import { graphql } from "gatsby";
import Seo from "../components/seo";
import Modules from "../components/home-modules";
import _ from "lodash";

const HomeTemplate = (props) => {
    console.log('home');
    const GQLPage = props.data.glstrapi?.article;
    const Page_Modules = props.data.glstrapi?.article?.Modules;
    const globalModule = props.data.glstrapi?.globalModule;
    const gardenSquares = props.data.glstrapi?.gardenSquares;
    const [show, setShow] = React.useState(false);

    React.useEffect(async() => {
        let form = await sessionStorage.getItem("form");
        if(form == null) {
            setTimeout(() => {
                setShow(true)
                sessionStorage.setItem("form", "true");
            }, 30000);
        }
        window.addEventListener('hashchange', function() {
            document.getElementById(window.location.hash.substring(1)).scrollIntoView({ behavior: "smooth" })
          }, false);

    }, []);

    return (
        <React.Fragment>
            <Seo
                title={GQLPage.Meta_Title} 
                description={GQLPage.Meta_Description}
                location={props.location}
            />
            <Helmet>
           
                <script type="application/ld+json">{`{
         
         "@context": "http://schema.org",
         "@type": "Organization",
         "@id": "https://www.tlclondon.com/#Organization",
         "url": "https://www.tlclondon.com/",
         "name": "${GQLPage.Meta_Title}",
         "description": "${GQLPage.Meta_Description}",
         "logo": "", 
         "aggregateRating": {
             "@type": "AggregateRating",
             "ratingValue": "4.9",
             "reviewCount": "178"
         },
         "sameAs": ["https://www.facebook.com/tlcREALestateagents", 
 "https://www.linkedin.com/company/tylden-lofts-and-calvert",
 "https://twitter.com/tlcestateagents", 
 "https://instagram.com/tlcestateagents", "https://www.youtube.com/channel/UC7wmgcJX1xgWru_EB--b0UQ"],
         "email": "salesteam@tlclondon.com",
             "location": [
             {
             "@type": "Place",
             "name": "tlc estate agents in Kensington",
             "telephone":"020 7370 4000",
             "address": {
         "@type": "PostalAddress",
             "streetAddress": "4th floor ",
             "addressLocality": "247 - 249 Cromwell Rd",
             "postalCode": "SW5 9GA",
             "addressCountry": "UK"
             }
         },
         {
       "@type": "Place",
       "name": "tlc lettings agents in Kensington",
       "telephone": "020 7370 4000",
       "address": {
        "@type": "PostalAddress",
        "streetAddress": "8 Hogarth Place",
        "addressRegion": "London",
        "postalCode": "SW5 0QT",
        "addressCountry": "UK"
         }
       }],
           "parentOrganization": {
           "@type": "Organization",
           "@id": "https://www.tlclondon.com/#Organization",
           "url": "https://www.tlclondon.com/",
           "name": "tlc estate & letting agents in Kensington | Chelsea",
           "alternateName": "tlc estate agents"
         }
    }`}
      </script>
            
            
            </Helmet>
            <Modules
                page={GQLPage}
                modules={Page_Modules}
                {...(_.omit(props, ["data"]))}
                globalModule={globalModule}
                gardenSquares={gardenSquares}
            />
        </React.Fragment>
    );
};

export default HomeTemplate;

export const pageQuery = graphql`
    query defaultHomeQuery($id: ID!) {
        glstrapi {
            article(id: $id, publicationState: LIVE) {
                _id
                Pagename
                Alias
                Meta_Title
                Meta_Description
                Layout
                Title
                Italic_Text
                Description
                CSS_Class
                Banner_Image {
                    alternativeText
                    url
                    url_sharp {
                        childImageSharp {
                            gatsbyImageData(
                                formats: AUTO
                                width: 1400
                                height: 660
                                placeholder: BLURRED
                                quality: 50
                                transformOptions: {
                                    cropFocus: CENTER, fit: COVER
                                }
                            )
                        }
                    }
                }
                imagetransforms
                Video_Url
                Show_Valuation_Block
                Popular_Search
                Modules {
                    ... on GLSTRAPI_ComponentComponentsMenuLinks {
                        _id
                        List_of_Links {
                            Anchor_or_External
                            Link_Type
                            Sub_Menu_Label
                            Sub_Menu_Link {
                                _id
                                Label
                                URL
                                menu_burgers {
                                    Sub_Menus {
                                        Label
                                        Link {
                                            _id
                                        }
                                    }
                                }
                            }
                        }
                    }
                    ... on GLSTRAPI_ComponentComponentsDynamicContents {
                        _id
                        Title
                        Content
                        Type
                        List_Title
                        Select_Team_Member {
                            Tile_Image {
                                alternativeText
                                url
                                url_sharp {
                                    childImageSharp {
                                        gatsbyImageData(
                                            formats: AUTO
                                            width: 110
                                            height: 104
                                            placeholder: BLURRED
                                            quality: 50
                                            transformOptions: {
                                                cropFocus: NORTH, fit: COVER
                                            }
                                        )
                                    }
                                }
                            }
                            Signature {
                                alternativeText
                                url
                            }
                            Designation
                            Mobile
                            Name
                            Email
                            Url
                            Video_Url
                            _id
                        }
                        List_Component {
                            _id
                            Title
                            Video_Url
                            Tile_Image {
                                alternativeText
                                url
                                url_sharp {
                                    childImageSharp {
                                        gatsbyImageData(
                                            formats: AUTO
                                            width: 1040
                                            height: 1182
                                            placeholder: BLURRED
                                            quality: 50
                                            transformOptions: {
                                                cropFocus: CENTER, fit: COVER
                                            }
                                        )
                                    }
                                }
                                url_sharp_sm: url_sharp {
                                    childImageSharp {
                                        gatsbyImageData(
                                            formats: AUTO
                                            width: 88
                                            height: 74
                                            placeholder: BLURRED
                                            quality: 50
                                            transformOptions: {
                                                cropFocus: NORTH, fit: COVER
                                            }
                                        )
                                    }
                                }
                            }
                            Sort
                            Publish
                            List_Link {
                                Label
                                _id
                                URL
                            }
                            List_Label
                            Content
                        }
                    }
                    ... on GLSTRAPI_ComponentComponentsAboutComponent {
                        id
                        _id
                        Title
                        Sm_Title
                        Italic_Text
                        Content
                        Data_Type
                        Cta_Label
                        Show_Review
                        Short_Video {
                            url
                        }
                        Tile_Image {
                            alternativeText
                            url
                            url_sharp {
                                childImageSharp {
                                    gatsbyImageData(
                                        formats: AUTO
                                        width: 1360
                                        height: 1040
                                        placeholder: BLURRED
                                        quality: 100
                                        transformOptions: {
                                            cropFocus: CENTER, fit: COVER
                                        }
                                    )
                                }
                            }
                        }
                        Select_Team {
                            Tile_Image {
                                alternativeText
                                url
                                url_sharp {
                                    childImageSharp {
                                        gatsbyImageData(
                                            formats: AUTO
                                            width: 110
                                            height: 104
                                            placeholder: BLURRED
                                            quality: 50
                                            transformOptions: {
                                                cropFocus: NORTH, fit: COVER
                                            }
                                        )
                                    }
                                }
                            }
                            Signature {
                                alternativeText
                                url
                            }
                            Designation
                            Mobile
                            Name
                            Email
                            Category {
                                Label: Title
                                _id
                            }
                            Url
                            Video_Url
                            _id
                        }
                        Video_Url
                        About_Cta_Link {
                            _id
                            URL
                        }
                    }
                    ... on GLSTRAPI_ComponentComponentsSelectCollection {
                        Choose_Collecttion
                        Title
                        _id
                    }                    
                }
            }
            globalModule {
                Book_A_Appointment {
                    _id
                    Book_Title
                    Description
                    Cta_Label
                    Cta_Link {
                        _id
                        URL
                    }
                }
                Lets_Work_Together {
                    _id
                    Title
                    Sm_Title
                    Cta_1_Label
                    Cta_2_Label
                    Cta_1_Link {
                        URL
                        _id
                    }
                    Cta_2_Link {
                        URL
                        _id
                    }
                    Background_Image {
                        url
                        alternativeText
                        url_sharp {
                            childImageSharp {
                                gatsbyImageData(
                                    formats: AUTO
                                    width: 1800
                                    placeholder: BLURRED
                                    quality: 50
                                    transformOptions: {
                                        cropFocus: CENTER, fit: COVER
                                    }
                                )
                            }
                        }
                    }
                }               
            }
           
        }
    }
`
